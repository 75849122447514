// Generated by Framer (4743357)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./NfTPjIwEM-0.js";
import ArrowDown from "./ZF1ah4dh9";
const ArrowDownFonts = getFonts(ArrowDown);

const cycleOrder = ["esD8oap2d", "uD3IhuTsk"];

const serializationHash = "framer-UYr3r"

const variantClassNames = {esD8oap2d: "framer-v-14w1ual", uD3IhuTsk: "framer-v-1lhfwqd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qMmWsyKjJ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "esD8oap2d", "Variant 2": "uD3IhuTsk"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, fLQNzfc2p: title ?? props.fLQNzfc2p ?? "Socials", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "esD8oap2d", zVoJj3IMI: hover ?? props.zVoJj3IMI} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fLQNzfc2p, zVoJj3IMI, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "esD8oap2d", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1qzopsy = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (zVoJj3IMI) {const res = await zVoJj3IMI(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-14w1ual", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"esD8oap2d"} onMouseEnter={onMouseEnter1qzopsy} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{uD3IhuTsk: {opacity: 0.5}}} {...addPropertyOverrides({uD3IhuTsk: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Qml0dGVyLXJlZ3VsYXI=", "--framer-font-family": "\"Bitter\", \"Bitter Placeholder\", serif", "--framer-letter-spacing": "0.02em", "--framer-line-height": "1.3em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-5900d408-4703-403c-84e4-19f57a96b1e6, rgb(153, 153, 153)))"}}>Socials</motion.p></React.Fragment>} className={"framer-13jz8di"} fonts={["GF;Bitter-regular"]} layoutDependency={layoutDependency} layoutId={"Xd06kI6zx"} style={{"--extracted-r6o4lv": "var(--token-5900d408-4703-403c-84e4-19f57a96b1e6, rgb(153, 153, 153))"}} text={fLQNzfc2p} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-121zocd-container"} layoutDependency={layoutDependency} layoutId={"IBpiJZcfC-container"}><ArrowDown height={"100%"} id={"IBpiJZcfC"} layoutId={"IBpiJZcfC"} style={{height: "100%", width: "100%"}} variant={"BBHYxfDgL"} width={"100%"} {...addPropertyOverrides({uD3IhuTsk: {variant: "EudZfsZZt"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UYr3r.framer-1filq5o, .framer-UYr3r .framer-1filq5o { display: block; }", ".framer-UYr3r.framer-14w1ual { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-UYr3r .framer-13jz8di { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-UYr3r .framer-121zocd-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UYr3r.framer-14w1ual { gap: 0px; } .framer-UYr3r.framer-14w1ual > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-UYr3r.framer-14w1ual > :first-child { margin-left: 0px; } .framer-UYr3r.framer-14w1ual > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 74
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"uD3IhuTsk":{"layout":["auto","auto"]}}}
 * @framerVariables {"fLQNzfc2p":"title","zVoJj3IMI":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNfTPjIwEM: React.ComponentType<Props> = withCSS(Component, css, "framer-UYr3r") as typeof Component;
export default FramerNfTPjIwEM;

FramerNfTPjIwEM.displayName = "Socials";

FramerNfTPjIwEM.defaultProps = {height: 21, width: 74};

addPropertyControls(FramerNfTPjIwEM, {variant: {options: ["esD8oap2d", "uD3IhuTsk"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, fLQNzfc2p: {defaultValue: "Socials", displayTextArea: false, title: "Title", type: ControlType.String}, zVoJj3IMI: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(FramerNfTPjIwEM, [{explicitInter: true, fonts: [{family: "Bitter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/bitter/v36/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfCLrEXFh2reU.woff2", weight: "400"}]}, ...ArrowDownFonts], {supportsExplicitInterCodegen: true})